<template>
  <v-btn
    color="primary"
    rounded
    depressed
    small
    class="my-2"
    @click="downloadZipFile"
    :loading="loading"
    :disabled="loading"
    ><b>{{ "Download cover photos as ZIP" }}</b></v-btn
  >
</template>

<script>
import JSZip from "jszip";
import { saveAs } from "file-saver";
import { downloadFileFromBFS } from "../services/fileService";
import config from "../config";
export default {
  props: {
    files: {
      type: Array
    },
    name: {
      type: String
    }
  },
  data: () => ({
    messageBox: {
      state: false,
      content: "",
      cssClass: "error",
      destination: ""
    },
    loading: false
  }),
  methods: {
    async downloadZipFile() {
      this.loading = true;
      let zip = new JSZip();
      let downloadPromise = this.files.map((elem) => {
        return this.downloadFile(elem);
      });

      const result = await Promise.all(downloadPromise);
      result.forEach((file, i) => {
        zip.file(this.files[i].fileName, file, { base64: true });
      });
      let name = this.name;
      zip.generateAsync({ type: "blob" }).then(function (blob) {
        saveAs(blob, name.concat("_files").concat(".zip"));
      });
      this.loading = false;
    },
    async downloadFile(elem) {
      try {
        const result = await downloadFileFromBFS(this.$axios, config, elem);
        return result.data;

        // var fileURL = window.URL.createObjectURL(new Blob([result.data]));
        // var fileLink = document.createElement("a");

        // fileLink.href = fileURL;
        // fileLink.setAttribute("download", elem.fileName);
        // document.body.appendChild(fileLink);

        // fileLink.click();
      } catch (error) {
        this.messageBox.content = `The additional files cannot be downloaded due to ${error}.`;
        this.messageBox.state = true;
        this.messageBox.cssClass = "error";
        this.messageBox.destination = "";
      }
    }
  }
};
</script>
