<template>
  <div>
    <MessageBox
      v-model="messageBox.state"
      :message-content="messageBox.content"
      :message-class="messageBox.cssClass"
      :destination="messageBox.destination"
    />
    <div id="displayCard">
      <v-card min-width="600px" class="pa-10">
        <h1>Events</h1>
        <div class="searchToolbar">
          <v-text-field
            v-model="searchInput"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
          <div class="ma-3">
            <!-- <vue-json-to-csv :json-data="events" :labels="csvLabels">
              <v-btn depressed>
                Download CSV
                <v-icon right>mdi-download</v-icon>
              </v-btn>
            </vue-json-to-csv> -->
            <v-dialog v-model="dialog" persistent fullscreen>
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary" dark depressed v-bind="attrs" v-on="on" @click="isNewEvent = true">
                  New Event
                </v-btn>
              </template>
              <v-card>
                <v-card-title>
                  <span class="headline">Event</span>
                  <v-switch
                    class="pa-0 ma-0 ml-5"
                    label="Public Event"
                    hide-details=""
                    v-model="isPublic"
                    :disabled="!isNewEvent"
                  ></v-switch>
                </v-card-title>
                <v-card-text>
                  <v-row>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        dense
                        outlined
                        label="Event Code"
                        required
                        v-model="eventCode"
                        :disabled="!isNewEvent"
                      ></v-text-field>
                      <v-text-field
                        dense
                        outlined
                        label="Event Name"
                        v-model="eventName"
                        :disabled="!isNewEvent"
                      ></v-text-field>

                      <v-text-field dense outlined label="Event Title" v-model="eventObject.title"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-select dense label="Event Tier" v-model="eventTier" outlined :items="[1, 2, 3]"></v-select>
                      <div style="display: flex; align-items: center">
                        <v-switch
                          class="pa-0 ma-0 mr-5"
                          label="Application Fee"
                          outlined
                          v-model="enableApplicationFee"
                        ></v-switch>
                        <v-currency-field
                          :disabled="!enableApplicationFee"
                          v-model="eventObject.applicationFee"
                          prefix="US$"
                        />
                      </div>
                      <v-text-field
                        dense
                        outlined
                        label="Landing Info ID"
                        v-model="eventObject.landingInfoId"
                        hide-details=""
                      ></v-text-field>
                      <v-checkbox v-model="eventObject.useLandingInfo" label="Use Landing Info"></v-checkbox>
                    </v-col>
                    <v-col cols="12">
                      <h5 class="mb-5">Description</h5>
                      <!-- <v-textarea v-model="description" label="Description" outlined></v-textarea> -->
                      <tip-tap-input v-model="eventObject.description" />
                    </v-col>

                    <v-col cols="12" sm="6">
                      <div style="display: flex; align-items: center; flex-direction: column">
                        <h5 class="mb-2">Effective & Expiry Date</h5>
                        <v-date-picker outlined v-model="dates" range :disabled="!isNewEvent"></v-date-picker>
                        <v-text-field
                          v-model="dateRangeText"
                          label="Effective and expiry dates"
                          prepend-icon="mdi-calendar"
                          readonly
                          outlined
                          :disabled="!isNewEvent"
                        ></v-text-field>
                      </div>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <div style="display: flex; align-items: center; flex-direction: column">
                        <h5 class="mb-2">Winner Announcement Date</h5>
                        <v-date-picker outlined v-model="winnerAnnouncedDate" :min="dates[1]"></v-date-picker>
                      </div>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="closeDialog"> Close </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="createNewEvent"
                    :disabled="addEventLoading"
                    :loading="addEventLoading"
                  >
                    Save
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </div>
        </div>

        <v-data-table
          :headers="headers.filter((header) => header.inTable)"
          :items="events"
          :search="searchInput"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :loading="loading"
        >
          <template v-slot:item.effectiveDate="{ item }">
            {{
              new Date(item.effectiveDate).toLocaleString("en-GB", {
                day: "numeric",
                month: "short",
                year: "numeric",
                hour: "numeric",
                minute: "2-digit"
              })
            }}
          </template>
          <template v-slot:item.expiryDate="{ item }">
            {{
              new Date(item.expiryDate).toLocaleString("en-GB", {
                day: "numeric",
                month: "short",
                year: "numeric",
                hour: "numeric",
                minute: "2-digit"
              })
            }}
          </template>
          <template v-slot:item.works="{ item }">
            <v-dialog v-model="item.viewEventWorks" v-if="item.eventCode">
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary" depressed v-bind="attrs" v-on="on"> View </v-btn>
              </template>
              <event-works-table :eventCode="item.eventCode" />
            </v-dialog>
          </template>
          <template v-slot:item.edit="{ item }">
            <v-btn @click="editEventDialog(item)" icon><v-icon>mdi-pencil</v-icon></v-btn>
          </template>
        </v-data-table>
      </v-card>
    </div>
  </div>
</template>

<script>
// import AddArtwork from "@/components/Platform/AddArtwork";

import PlatformLayout from "@/layouts/Platform";

import { getAllEvents } from "@/services/workService";
import { createNewEvent, editEvent } from "@/services/adminService";

import MessageBox from "@/components/Common/MessageBox";
import config from "@/config.js";
import EventWorksTable from "./EventWorksTable.vue";
import VCurrencyField from "../../../components/VCurrencyField.vue";
import TipTapInput from "../../../components/TipTapInput.vue";
import { uploadCoverPhotoToBFS } from "../../../services/fileService";

// import VueJsonToCsv from "vue-json-to-csv";

export default {
  name: "AdminEventFunctions",
  components: {
    MessageBox,
    EventWorksTable,
    VCurrencyField,
    TipTapInput

    // VueJsonToCsv
  },
  data: () => ({
    files: [],
    expand: false,
    openIdx: -1,
    sortBy: "effectiveDate",
    sortDesc: true,
    headers: [
      {
        text: "Event Code",
        value: "eventCode",
        align: "left",
        sortable: false,
        inputField: false,
        inTable: true
      },
      {
        text: "Event Name",
        align: "left",
        sortable: true,
        value: "eventName",
        inTable: true
      },
      {
        text: "Effective Date",
        align: "left",
        sortable: true,
        value: "effectiveDate",
        inTable: true
      },
      {
        text: "Expiry Date",
        align: "left",
        sortable: false,
        value: "expiryDate",
        inTable: true
      },
      {
        text: "Works",
        align: "left",
        sortable: false,
        value: "works",
        inTable: true
      },
      {
        text: "Edit",
        align: "left",
        sortable: false,
        value: "edit",
        inTable: true
      }
    ],

    dates: [
      new Date().toISOString().substr(0, 10),
      new Date(new Date().getTime() + 2.628e9).toISOString().substr(0, 10)
    ],
    eventCode: "",
    eventName: "",
    events: [],
    addrList: {},
    editedIndex: -1,
    timer: null,
    searchField: null,
    searchInput: null,
    addingArtwork: false,
    pageNumber: 1,
    itemsPerPage: 5,
    pageCount: 1,
    messageBox: {
      state: false,
      content: "",
      cssClass: "error",
      destination: ""
    },
    dialog: false,
    viewItem: "",
    loading: false,
    eventObject: {
      title: "",
      description: "",
      applicationFee: null,
      coverPhoto: null,
      landingInfoId: null,
      useLandingInfo: false
    },
    eventTier: 1,
    winnerAnnouncedDate: new Date(new Date().getTime() + 2.628e9).toISOString().substr(0, 10),
    enableApplicationFee: false,
    applicationFee: null,
    isPublic: false,
    isNewEvent: false,
    addEventLoading: false
  }),
  computed: {
    dateRangeText() {
      return this.dates.join(" ~ ");
    },
    effectiveDate() {
      return new Date(this.dates[0]).getTime();
    },
    expiryDate() {
      return new Date(this.dates[1]).getTime();
    }
  },
  mounted() {
    this.initialize();
  },
  async created() {
    this.$emit(`update:layout`, PlatformLayout);
    const role = this.$session.get("role");
    if (role !== "AN") {
      this.$router.push({ name: "Not Found" });
      return;
    }

    this.role = this.$session.get("role");
    if (this.events.length === 0 && this.role === "member") {
      this.timer = setInterval(() => {
        this.role = this.$session.get("role");

        if (this.role !== "member") {
          clearInterval(this.timer);
          this.initialize();
        }
      }, 1000);
    } else {
      // to deal with refresh
      clearInterval(this.timer);
      this.initialize();
    }
  },
  methods: {
    async initialize() {
      this.loading = true;
      //  Get request to server
      const token = this.$session.get("token");
      const result = await getAllEvents(this.$axios, config, token, true, false);

      if (result.status === 200) {
        this.events = result.data;
      } else {
        this.messageBox.content = this.$t(result.data.message);
        this.messageBox.cssClass = "error";
        this.messageBox.state = true;
        this.messageBox.destination = "";
      }
      this.loading = false;
    },
    async createNewEvent() {
      if (this.eventCode === "") {
        this.messageBox = {
          content: `Event code cannot be empty.`,
          cssClass: "error",
          state: true,
          destination: ""
        };
      }
      if (this.eventName === "") {
        this.messageBox = {
          content: `Event name cannot be empty.`,
          cssClass: "error",
          state: true,
          destination: ""
        };
      }
      if (isNaN(this.effectiveDate)) {
        this.messageBox = {
          content: `Effective date cannot be empty.`,
          cssClass: "error",
          state: true,
          destination: ""
        };
      }
      if (isNaN(this.expiryDate)) {
        this.messageBox = {
          content: `Expiry date cannot be empty.`,
          cssClass: "error",
          state: true,
          destination: ""
        };
      }

      const token = this.$session.get("token");
      let result;
      const payload = {
        eventCode: this.eventCode,
        eventName: this.eventName,
        effectiveDate: this.effectiveDate < new Date().getTime() ? new Date().getTime() + 10000 : this.effectiveDate,
        expiryDate: this.expiryDate,
        isPublic: this.isPublic
      };
      if (this.description !== "") {
        payload.eventObject = JSON.stringify(this.eventObject);
      }
      if (this.winnerAnnouncedDate) {
        payload.winnerAnnouncedDate = new Date(this.winnerAnnouncedDate).getTime();
      }
      if (this.enableApplicationFee) {
        payload.applicationFee = this.applicationFee;
      }

      if (this.isNewEvent) {
        result = await createNewEvent(this.$axios, config, token, payload);
      } else {
        delete payload.effectiveDate;
        result = await editEvent(this.$axios, config, token, payload, this.eventCode, this.$session.get("keypair"));
      }

      if (result.status === 200) {
        this.messageBox = {
          content: this.isNewEvent ? `New event created` : "Event modified",
          cssClass: "message",
          state: true,
          destination: ""
        };
        setTimeout(this.initialize, 1000);
      } else {
        this.messageBox.content = this.$t(result.data.message);
        this.messageBox.cssClass = "error";
        this.messageBox.state = true;
        this.messageBox.destination = "";
      }

      this.dialog = false;
    },
    closeDialog() {
      this.dialog = false;
      this.isNewEvent = false;
      this.resetFields();
    },
    resetFields() {
      this.eventCode = "";
      this.eventName = "";
      this.eventObject = {
        title: "",
        description: "",
        applicationFee: null
      };
      this.eventTier = 1;
      this.enableApplicationFee = null;
      this.dates = [
        new Date().toISOString().substr(0, 10),
        new Date(new Date().getTime() + 2.628e9).toISOString().substr(0, 10)
      ];
      this.winnerAnnouncedDate = new Date(new Date().getTime() + 2.628e9).toISOString().substr(0, 10);
      this.isPublic = false;
    },
    editEventDialog(item) {
      this.isNewEvent = false;
      this.eventCode = item.eventCode;
      this.eventName = item.eventName;
      this.isPublic = item.isPublic;
      this.dates = [
        new Date(item.effectiveDate).toISOString().substr(0, 10),
        new Date(item.expiryDate).toISOString().substr(0, 10)
      ];
      if (item.eventTier) {
        this.eventTier = item.eventTier;
      }
      if (item.applicationFee) {
        this.enableApplicationFee = item.applicationFee;
      }
      if (item.winnerAnnouncedDate) {
        this.winnerAnnouncedDate = new Date(item.winnerAnnouncedDate).toISOString().substr(0, 10);
      }

      function validatingJSON(json) {
        var checkedjson;

        try {
          checkedjson = JSON.parse(json);
        } catch (e) {
          e;
        }

        return checkedjson;
      }

      if (item.eventObject && validatingJSON(item.eventObject)) {
        let eventObject = JSON.parse(item.eventObject);
        this.eventObject = eventObject;
      }
      this.dialog = true;
    },
    async uploadCoverImage(fieldName, file) {
      this.addEventLoading = true;
      let coverImageFileDescriptor;
      let imageFile = file[0];
      let formData = new FormData();
      let imageURL = URL.createObjectURL(imageFile);
      formData.append(fieldName, imageFile);
      // Emit the FormData and image URL to the parent component
      const body = { formData, imageURL, size: imageFile.size };
      const token = this.$session.get("token");
      const coverImageRequest = await uploadCoverPhotoToBFS(this.$axios, config, body.formData, token);

      if (coverImageRequest.status === 200) {
        coverImageFileDescriptor = coverImageRequest.data.fileDescriptor;
        this.eventObject.coverPhoto = coverImageFileDescriptor;
      } else if (coverImageRequest.status === 500) {
        this.messageBox.content = this.$t("dialogMsg.myCollection.blockchainFail");
        this.messageBox.state = true;
        this.messageBox.destination = "";
        this.$store.commit("showProgressLine", false);
      } else {
        this.messageBox.content = `The cover picture cannot be uploaded.`;
        this.messageBox.state = true;
        this.messageBox.cssClass = "error";
        this.messageBox.destination = "";
      }
      this.addEventLoading = false;
    }
  }
};
</script>
