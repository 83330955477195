var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"headers":_vm.headers.filter(function (header) { return header.inTable; }),"items":_vm.works,"search":_vm.searchInput,"sort-by":"createdAt","loading":_vm.loading},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"d-flex align-center ma-2"},[_c('download-as-zip',{attrs:{"files":_vm.coverPhotoArray,"name":_vm.eventCode}})],1)]},proxy:true},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(new Date(item.createdAt).toLocaleString("en-GB", { day: "numeric", month: "numeric", year: "numeric", hour: "numeric", minute: "2-digit" }))+" ")]}},{key:"item.viewInBazaar",fn:function(ref){
var item = ref.item;
return [_c('v-dialog',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"primary"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-earth")])],1)]}}],null,true),model:{value:(item.viewArtwork),callback:function ($$v) {_vm.$set(item, "viewArtwork", $$v)},expression:"item.viewArtwork"}},[_c('v-card',{attrs:{"flat":""}},[_c('div',{staticClass:"ma-0",attrs:{"id":"displayCard"}},[_c('div',{staticClass:"addArtwork"},[_c('div',{staticClass:"newArtworkContainer"},[_c('artwork-display',{staticClass:"artworkDisplay",attrs:{"displayItem":item,"hideActions":true}})],1)])]),_c('v-divider'),_c('v-card-actions',[(item.workStatus === 2 && !item.isHidden)?_c('v-btn',{attrs:{"color":"secondary","depressed":"","to":("/platform/artwork/" + (item.workid))}},[_c('b',[_vm._v("View in Bazaar")])]):_vm._e(),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","depressed":""},on:{"click":function($event){item.viewArtwork = false}}},[_c('b',[_vm._v("Close")])])],1)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }