<template>
  <div>
    <div v-if="editor">
      <button @click="editor.chain().focus().toggleBold().run()" :class="{ 'is-active': editor.isActive('bold') }">
        <v-icon>mdi-format-bold</v-icon>
      </button>
      <button @click="editor.chain().focus().toggleItalic().run()" :class="{ 'is-active': editor.isActive('italic') }">
        <v-icon>mdi-format-italic</v-icon>
      </button>
      <button @click="editor.chain().focus().toggleStrike().run()" :class="{ 'is-active': editor.isActive('strike') }">
        <v-icon>mdi-format-strikethrough</v-icon>
      </button>

      <button
        @click="editor.chain().focus().setParagraph().run()"
        :class="{ 'is-active': editor.isActive('paragraph') }"
      >
        <v-icon>mdi-format-paragraph</v-icon>
      </button>
      <button
        @click="editor.chain().focus().toggleHeading({ level: 1 }).run()"
        :class="{ 'is-active': editor.isActive('heading', { level: 1 }) }"
      >
        <v-icon>mdi-format-header-1</v-icon>
      </button>
      <button
        @click="editor.chain().focus().toggleHeading({ level: 2 }).run()"
        :class="{ 'is-active': editor.isActive('heading', { level: 2 }) }"
      >
        <v-icon>mdi-format-header-2</v-icon>
      </button>
      <button
        @click="editor.chain().focus().toggleHeading({ level: 3 }).run()"
        :class="{ 'is-active': editor.isActive('heading', { level: 3 }) }"
      >
        <v-icon>mdi-format-header-3</v-icon>
      </button>
      <button
        @click="editor.chain().focus().toggleHeading({ level: 4 }).run()"
        :class="{ 'is-active': editor.isActive('heading', { level: 4 }) }"
      >
        <v-icon>mdi-format-header-4</v-icon>
      </button>
      <button
        @click="editor.chain().focus().toggleHeading({ level: 5 }).run()"
        :class="{ 'is-active': editor.isActive('heading', { level: 5 }) }"
      >
        <v-icon>mdi-format-header-5</v-icon>
      </button>
      <button
        @click="editor.chain().focus().toggleHeading({ level: 6 }).run()"
        :class="{ 'is-active': editor.isActive('heading', { level: 6 }) }"
      >
        <v-icon>mdi-format-header-6</v-icon>
      </button>
      <button
        @click="editor.chain().focus().toggleBulletList().run()"
        :class="{ 'is-active': editor.isActive('bulletList') }"
      >
        <v-icon>mdi-format-list-bulleted</v-icon>
      </button>
      <button
        @click="editor.chain().focus().toggleOrderedList().run()"
        :class="{ 'is-active': editor.isActive('orderedList') }"
      >
        <v-icon>mdi-format-list-ordered</v-icon>
      </button>
      <button @click="editor.chain().focus().undo().run()"><v-icon>mdi-undo</v-icon></button>
      <button @click="editor.chain().focus().redo().run()"><v-icon>mdi-redo</v-icon></button>
      <button @click="editor.chain().focus().unsetAllMarks().run()" depressed><v-icon>mdi-format-clear</v-icon></button>
      <button @click="addImage" depressed><v-icon>mdi-image</v-icon></button>
      <button @click="setLink" :class="{ 'is-active': editor.isActive('link') }">
        <v-icon>mdi-link-variant</v-icon>
      </button>
      <v-btn @click="editor.chain().focus().unsetLink().run()" v-if="editor.isActive('link')" depressed rounded
        >Remove link</v-btn
      >
    </div>
    <editor-content class="mt-5" style="border: 1px solid; border-radius: 5px" :editor="editor" />
  </div>
</template>

<script>
import { Editor, EditorContent } from "@tiptap/vue-2";
import { defaultExtensions } from "@tiptap/starter-kit";
import Image from "@tiptap/extension-image";
import Dropcursor from "@tiptap/extension-dropcursor";
import Link from "@tiptap/extension-link";

export default {
  components: {
    EditorContent
  },

  data() {
    return {
      editor: null
    };
  },
  props: {
    value: {
      type: String,
      default: ""
    }
  },

  mounted() {
    this.editor = new Editor({
      extensions: [...defaultExtensions(), Image, Dropcursor, Link],
      content: this.value,
      onUpdate: () => {
        // HTML
        this.$emit("input", this.editor.getHTML());

        // JSON
        // this.$emit('input', this.editor.getJSON())
      }
    });
  },

  value(value) {
    // HTML
    const isSame = this.editor.getHTML() === value;

    // JSON
    // const isSame = this.editor.getJSON().toString() === value.toString()

    if (isSame) {
      return;
    }

    this.editor.commands.setContent(this.value, false);
  },

  beforeDestroy() {
    this.editor.destroy();
  },
  methods: {
    addImage() {
      const url = window.prompt("URL");

      if (url) {
        this.editor.chain().focus().setImage({ src: url }).run();
      }
    },
    setLink() {
      const url = window.prompt("URL");

      this.editor.chain().focus().setLink({ href: url }).run();
    }
  }
};
</script>

<style lang="scss">
/* Basic editor styles */
.ProseMirror {
  > * + * {
    margin-top: 0.75em;
  }

  ul,
  ol {
    padding: 0 1rem;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.1;
  }

  code {
    background-color: rgba(#616161, 0.1);
    color: #616161;
  }

  pre {
    background: #0d0d0d;
    color: #fff;
    font-family: "JetBrainsMono", monospace;
    padding: 0.75rem 1rem;
    border-radius: 0.5rem;

    code {
      color: inherit;
      padding: 0;
      background: none;
      font-size: 0.8rem;
    }
  }

  img {
    max-width: 100%;
    height: auto;
  }

  blockquote {
    padding-left: 1rem;
    border-left: 2px solid rgba(#0d0d0d, 0.1);
  }

  hr {
    border: none;
    border-top: 2px solid rgba(#0d0d0d, 0.1);
    margin: 2rem 0;
  }
}
</style>
