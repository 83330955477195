<template>
  <v-data-table
    :headers="headers.filter((header) => header.inTable)"
    :items="works"
    :search="searchInput"
    sort-by="createdAt"
    :loading="loading"
  >
    <template v-slot:top>
      <div class="d-flex align-center ma-2">
        <download-as-zip :files="coverPhotoArray" :name="eventCode" /></div
    ></template>
    <template v-slot:item.createdAt="{ item }">
      {{
        new Date(item.createdAt).toLocaleString("en-GB", {
          day: "numeric",
          month: "numeric",
          year: "numeric",
          hour: "numeric",
          minute: "2-digit"
        })
      }}
    </template>
    <template v-slot:item.viewInBazaar="{ item }">
      <!-- <v-btn icon>
              <v-icon color="primary" v-if="item.workStatus === 2 && !item.isHidden" @click="goToBazaar(item.workid)">
                mdi-earth
              </v-icon>
            </v-btn> -->
      <v-dialog v-model="item.viewArtwork">
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on" color="primary"> <v-icon>mdi-earth</v-icon> </v-btn>
        </template>

        <v-card flat>
          <div id="displayCard" class="ma-0">
            <div class="addArtwork">
              <div class="newArtworkContainer">
                <artwork-display :displayItem="item" class="artworkDisplay" :hideActions="true" />
              </div>
            </div>
          </div>
          <v-divider></v-divider>

          <v-card-actions>
            <v-btn
              color="secondary"
              depressed
              :to="`/platform/artwork/${item.workid}`"
              v-if="item.workStatus === 2 && !item.isHidden"
            >
              <b>View in Bazaar</b></v-btn
            >
            <v-spacer></v-spacer>
            <v-btn color="primary" depressed @click="item.viewArtwork = false"> <b>Close</b> </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
  </v-data-table>
</template>

<script>
import DownloadAsZip from "../../../components/downloadAsZip.vue";
import ArtworkDisplay from "../../../components/Platform/ArtworkDisplay.vue";
import config from "../../../config";
import { viewEventWorks } from "../../../services/adminService";
export default {
  components: { ArtworkDisplay, DownloadAsZip },
  name: "EventWorksTable",
  data: () => ({
    works: [],
    coverPhotoArray: [],
    headers: [
      {
        text: "Work ID",
        align: "left",
        sortable: true,
        value: "workid",
        inTable: true
      },
      // {
      //   text: "Creator Address",
      //   align: "left",
      //   sortable: true,
      //   value: "creatorAddress",
      //   inTable: true
      // },
      // {
      //   text: "Owner Address",
      //   align: "left",
      //   sortable: false,
      //   value: "ownerAddress",
      //   inTable: true
      // },
      {
        text: "Approval Status",
        align: "left",
        sortable: true,
        value: "approvalStatus",
        inTable: true
      },
      {
        text: "Work Status",
        align: "left",
        sortable: false,
        value: "workStatus",
        inTable: true
      },
      {
        text: "Hidden",
        align: "left",
        sortable: false,
        value: "isHidden",
        inTable: true
      },
      {
        text: "Artwork Title",
        align: "left",
        sortable: true,
        value: "workInfo.artworkTitle",
        inTable: true
      },
      {
        text: "Artwork Type",
        align: "left",
        sortable: true,
        value: "workInfo.artworkType",
        inTable: true
      },
      {
        text: "Artwork Class",
        align: "left",
        sortable: true,
        value: "workInfo.artworkClass",
        inTable: true
      },
      {
        text: "Created At",
        align: "left",
        sortable: true,
        value: "createdAt",
        inTable: true
      },

      {
        text: "Open",
        align: "left",
        sortable: true,
        value: "viewInBazaar",
        inTable: true
      }
    ],
    searchInput: "",
    loading: false
  }),
  props: ["eventCode"],
  async created() {
    this.loading = true;
    const token = this.$session.get("token");
    if (this.eventCode) {
      const result = await viewEventWorks(this.$axios, config, token, this.eventCode);
      if (result.status === 200) {
        this.works = result.data;
        this.coverPhotoArray = [];
        result.data.forEach((elem) => {
          this.coverPhotoArray = this.coverPhotoArray.concat(elem.coverPhotos);
        });
      } else if (result.status === 500) {
        this.messageBox.content = this.$t("dialogMsg.myCollection.blockchainFail");
        this.messageBox.state = true;
        this.messageBox.destination = "";
        this.$store.commit("showProgressLine", false);
      } else {
        this.works = [];
      }
      this.loading = false;
    }
  }
};
</script>
